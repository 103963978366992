(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('taken.controller', ['$rootScope', '$scope', '$element', '$timeout', '$interval', '$filter', '$localStorage', '$q', 'toastr', 'PagePanel', 'api.factory', 'db.factory', 'log.factory', 'map.factory', 'task.factory', 'modal.service', function ($rootScope, $scope, $element, $timeout, $interval, $filter, $localStorage, $q, toastr, PagePanel, _api, _db, _log, _map, _task, _modal) {

        // Variabeles
        var request = null;
        var abortDialog = null;

        // Page
        $scope.page = {
            main: true,
            open: false
        }

        $scope.sw = {};

        // Date
        $scope.date = {
            dateFormat: $rootScope.settings.dateFormat,
            dateTimeFormat: $rootScope.settings.dateFormat + ' HH:mm',
            type: {
                name: 'today', from: new Date().startDay(), to: new Date().endDay()
            },
            types: [
                { name: 'today', from: new Date().startDay(), to: new Date().endDay() },
                { name: 'thisweek', from: new Date().firstDayOfWeek(), to: new Date().lastDayOfWeek() },
                { name: 'thismonth', from: new Date().firstDayOfMonth(), to: new Date().lastDayOfMonth() },
                { name: 'thisyear', from: new Date().firstDayOfYear(), to: new Date().lastDayOfYear() }
            ],
            from: '',
            to: ''
        }

        // Find allowed sub activities
        $scope.SubActivities = function (task) {            
            return _.filter($localStorage.activities, function (activity) {
                return (activity.AllowCreateTask && _.indexOf(task.AllowedSubCdoTypes, activity.CdoId) > -1);
            });
        }

        // Find allowed activities
        $scope.MainTaskActivities = function () {
            var cdoTypes = [];
            if (!_.isUndefined($localStorage.defaultProject) && !_.isNull($localStorage.defaultProject)) {
                cdoTypes = $localStorage.defaultProject.AllowedCdoTypes;
            } else {
                if ($localStorage.allowedCdoTypesForDefaultProjectHandle.length > 0) {
                    cdoTypes = $localStorage.allowedCdoTypesForDefaultProjectHandle;
                } else {
                    cdoTypes = $localStorage.allowedCdoTypesForRoot;
                }
            }
            return _.filter($localStorage.activities, function (activity) {
                return (activity.AllowCreateTask && _.indexOf(cdoTypes, activity.CdoId) > -1);
            });
        }

        // Define mainTask activities
        $scope.mainTaskActivities = $scope.MainTaskActivities();

        // Is geometry available
        $scope.isGeometryAvailable = function (task) {
            return !_.isUndefined(_.find($localStorage.cdotypes, { 'CdoId': task.CdoId }));
        }

        // Select type
        $scope.selectType = function (type) {
            $scope.date.type = type;
            if (!_.isNull(type.from))
                $scope.date.from = type.from;
            if (!_.isNull(type.to))
                $scope.date.to = type.to;
        }

        // Tasks
        $scope.tasks = [];
        $rootScope.newTask = null;
        $rootScope.selectedTask = null;
        $scope.selectedTask = null;

        // Main tasks
        $scope.load = function () {
            // Close
            $scope.page.open = false;
            // Not main page
            $scope.page.main = true;
            // Close child panel if opened
            $scope.closeChild();
            // Clear new task
            $rootScope.newTask = null;
            // Clear selectedTask
            $rootScope.selectedTask = null;
            // Clear tasks
            $scope.tasks = [];
            // Clear marker
            _map.marker.clear();
            // Online
            if ($rootScope.applicationOnline) {
                _task.dirty().then(function (tasks) {
                    // No dirty tasks
                    if (tasks.length == 0) {

                        // Show loading
                        $rootScope.$broadcast('loading:show');

                        // Abort pending request
                        abortDialog = _modal.alert({ text: $filter('translate')('labels.loadingdatapleasewait'), ok: $filter('translate')('labels.abort') });
                        abortDialog.result.then(function () {
                            // Abort request
                            if (request)
                                request.abort();
                            request = null;
                            abortDialog = null;
                        });

                        // Clear tasks
                        _db.tasks.clear();

                        // Tasks
                        (request = _api.getTasks($scope.date.from.toISOString(), $scope.date.to.toISOString())).then(function (result) {
                            // Add tasks to db
                            _db.tasks.add(result.data).then(function () {
                                // Load from db
                                $scope.reload().finally(function () {
                                    // Hide loading
                                    $rootScope.$broadcast('loading:hide');
                                });
                            });
                        }, function (error) {
                            // Write to log
                            _log.error("taken.controller", "getTasks", error);

                            // Display error
                            var message = $filter('error')(error);
                            if (!_.isEmpty(message))
                                toastr.error(message, { allowHtml: true });

                        }).finally(function () {
                            // Hide loading
                            $rootScope.$broadcast('loading:hide');
                            // Remove abort dialog
                            abortDialog.dismiss('cancel');
                            abortDialog = null;
                        });
                    } else {
                        // Show confirm
                        _modal.alert({ text: $filter('translate')('labels.syncfounddatadirty'), ok: $filter('translate')('labels.ok') }).result.then(function () {
                            // Show loading
                            $rootScope.$broadcast('loading:show');
                            // Moeten we hier misschien de taken uit de indexedDB halen?
                            $scope.reload().finally(function () {
                                // Hide loading
                                $rootScope.$broadcast('loading:hide');
                            });
                        });
                    }
                });
            } else {
                // Load tasks from db
                _modal.alert({ text: $filter('translate')('messages.applicationofflinecache'), ok: $filter('translate')('labels.ok') }).result.then(function () {
                    // Show loading
                    $rootScope.$broadcast('loading:show');
                    // Load from cache
                    $scope.reload().finally(function () {
                        // Hide loading
                        $rootScope.$broadcast('loading:hide');
                    });
                });
            }
        };

        // Load tasks from IndexedDB
        $scope.reload = function () {

            // Defer
            var defer = $q.defer();

            // Load from DB
            _db.tasks.all().then(function (tasks) {
                if (!_.isUndefined($scope.parent) && !_.isNull($scope.parent)) {

                    // Main page
                    $scope.page.main = false;

                    // Subtasks
                    $scope.tasks = _.filter(tasks, function (task) {
                        return ((task.ParentTaskId == $scope.parent.TaskId));
                    });
                } else {

                    // Main page
                    $scope.page.main = true;

                    // Maintask
                    $scope.tasks = _.filter(tasks, function (task) {
                        return ((task.ParentTaskId == null || task.ParentTaskId == -1) &&
                            (new Date(task.PlannedStartTime).clearTime().getTime() >= new Date($scope.date.from).clearTime().getTime()) &&
                            (new Date(task.PlannedEndTime).clearTime().getTime() <= new Date($scope.date.to).clearTime().getTime()));
                    });
                }
                // Resolve
                defer.resolve();
            }, function (error) {
                defer.reject(error);
            });

            // Return promise
            return defer.promise;
        };

        // Add task
        $scope.addTask = function ($event, task, activity) {

            // When copy stop event
            if (!_.isNull(task)) {
                // Stop event
                $event.preventDefault();
                $event.stopPropagation();
            }

            // Check if creating task is allowed
            if (!activity.AllowCreateTask)
                return;

            // Check if newTask is saved    
            if (!_.isNull($rootScope.newTask) && !$rootScope.newTask.Dirty) {

                // Dialog to cancel the pending request
                _modal.alert({ text: $filter('translate')('messages.unsavedtaskmsg'), ok: $filter('translate')('labels.ok') }).result.then(function () {
                    // Select task
                    $scope.selectTask($event, $rootScope.newTask, true);
                    // Scroll to save/cancel button
                    $scope.scrollTask($rootScope.newTask, true);
                });
                return;
            }

            if (_.isNull(task)) {

                // Clone activity
                activity = angular.copy(activity);

                // Create new task
                task = {
                    Copy: false,
                    ObjectHandle: 0,
                    Description: $filter('translate')('labels.newtaskdescription'),
                    Activity: activity,
                    ActivityId: activity.ActivityId,
                    CdoName: activity.CdoName,
                    CdoId: activity.CdoId,
                    Photo: null,
                    Signature: null,
                    TaskId: 0,
                    PlannedStartTime: new Date(),
                    PlannedEndTime: new Date(),
                    ParentTaskId: $scope.id, // ParentTaskId
                    Coordinates: null
                }

                // Add projectHandle
                if (!_.isUndefined($scope.parent)) {
                    task.ProjectHandle = $scope.parent.ProjectHandle;
                } else {
                    var projectHandle = 0;
                    if ($localStorage.defaultProject !== undefined && $localStorage.defaultProject != null) {
                        projectHandle = $localStorage.defaultProject.Handle;
                    } else {
                        // 'DefaultProjectHandle'
                        if ($rootScope.settings.defaultProjectHandle !== undefined && $rootScope.settings.defaultProjectHandle != null) {
                            projectHandle = $rootScope.settings.defaultProjectHandle;
                        }
                    }
                    task.ProjectHandle = projectHandle;
                }

            } else {

                // Copy task
                task = angular.extend({}, task);

                // Remove files
                task.Files = [];

                // Mark as copy
                task.Copy = true;

                // No subtasks for main task.
                if (_.isNull(task.ParentTaskId) || task.ParentTaskId == -1)
                    task.HasSubTasks = false;

                // PlannedStart/PlannedEnd
                task.PlannedStartTime = new Date(),
                task.PlannedEndTime = new Date(),

                // Description
                task.Description += ' (' + $filter('translate')('labels.copy') + ')';
            }

            // Id
            task.Id = 0;
            // Finished
            task.Finished = false;

            // ActualEnd / ActualStart
            task.ActualEndTime = null;
            task.ActualStartTime = null;

            // Dirty
            task.Dirty = false;

            // Object Handle
            task.ObjectHandle = 0;

            // TaskId
            task.TaskId = 0,

            // Parent TaskId
            task.ParentTaskId = parseInt($scope.id);

            // Parent Id
            task.ParentId = 0;
            if (!_.isUndefined($scope.parent))
                task.ParentId = parseInt($scope.parent.Id);

            // Change status to planned
            task.Status = "Planned";

            // Remove readonly from activity columns
            _.each(task.Activity.Columns, function (column) {
                column.Readonly = false;
            });

            // Add to collection
            $rootScope.newTask = task;

            // Add to tasks
            $scope.tasks = $scope.tasks.concat([$rootScope.newTask]);

            // Select and open task
            $scope.selectTask($event, task, true);
        }

        // Cancel task
        $scope.cancelTask = function ($event, task) {
            if (task.Id == 0) {
                // Remove from tasks
                $scope.tasks = _.without($scope.tasks, task);
                // Remove from list
                $rootScope.newTask = null;
                // Remove as selected task
                $rootScope.selectedTask = null;
                // Stop drawing
                $rootScope.$broadcast('application:draw:stop');
                // Clear marker
                _map.marker.clear();
            } else {
                _db.tasks.get(task.Id).then(function (task) {
                    // Replace task with original task
                    $scope.tasks.splice(_.indexOf($scope.tasks, _.find($scope.tasks, { Id: task.Id })), 1, task);
                    // Select task
                    $scope.selectTask($event, task, true);
                });
            }
        }

        // Save task
        $scope.saveTask = function ($event, task, frm) {
            // Set dirty
            task.Dirty = true;
            // Status
            task.Status = "Started";
            
            if ($rootScope.settings.useStopWatch) {
                $scope.sw.stopStopWatch();
            }
            // Not finished or finished but no subtasks
            if (!task.Finished || (task.Finished && !task.HasSubTasks)) {
                // Set finished
                if (task.Finished)
                    task.Status = "Finished";
                // Continue save
                if ($scope.continueSaveTask(task))
                    frm.$setPristine(false);
            } else {
                // If finished checked then validate subtasks have 'finished'.
                _task.subtasksFinished(task).then(function (finished) {
                    if (!finished) {
                        _modal.alert({ text: $filter('translate')('messages.notallsubtasksfinishedmessage'), ok: $filter('translate')('labels.ok') });
                    } else {
                        // Set finished
                        if (task.Finished)
                            task.Status = "Finished";
                        // Continue save
                        if ($scope.continueSaveTask(task))
                            frm.$setPristine(false);
                    }
                });
            }
        }

        // Continue save task
        $scope.continueSaveTask = function (task) {

            // NewTask
            if (task.Id == 0)
                delete task.Id;

            // Save task
            return _db.tasks.upsert(task).then(function (tasks) {
                // Set ID
                task.Id = _.first(tasks);
                // Saved
                toastr.success($filter('translate')('messages.savedsucces'));
                // Success
                return true;
            }, function (error) {
                // Write to log
                _log.error("taken.controller", "saveTask", error);
                // Toastr
                toastr.error($filter('translate')('messages.savedfailure'));
                // Failed
                return false;
            }).finally(function () {
                // Update dirty
                $rootScope.$broadcast('application:tasks:dirty');
            });
        };

        // Scroll to task
        $scope.scrollTask = function (task, scrollToSave) {
            // Scroll to form
            $timeout(function () {
                // Scroll to header or save button
                var elem = $('#task-' + task.Id);
                if (scrollToSave)
                    elem = $('#task-' + task.Id + ' .glyphicon-ok');
                angular.element($element[0].querySelector(".frame-container")).scrollToElementAnimated(elem, 30);
            });
        };

        // Select task
        $scope.selectTask = function ($event, task, form) {

            // Keep form open when current task and form is open.
            if (!form && $rootScope.selectedTask == task && $scope.page.open)
                form = true;



            // Close / Open form
            $scope.page.open = form;
            // Set selected task
            $rootScope.selectedTask = task
            $scope.selectedTask = task;

            // Is opened for the first time?
            task.FirstOpened = false;
            if (task.ActualStartTime == null) {
                task.FirstOpened = true;
                task.ActualStartTime = new Date();
            }

            // Clear marker
            _map.marker.clear();

            // Set marker
            if (!_.isNull($rootScope.selectedTask)) {
                if (!_.isUndefined($rootScope.selectedTask.Coordinates) && !_.isNull($rootScope.selectedTask.Coordinates) && $rootScope.selectedTask.Coordinates.length > 0) {
                    // Add marker to map
                    _map.marker.add(ol.proj.fromLonLat([$rootScope.selectedTask.Coordinates[0].Longitude, $rootScope.selectedTask.Coordinates[0].Latitude]), _map.map.getView().getZoom());
                } else {
                    // Parent task
                    if (!_.isUndefined($scope.parent) && !_.isUndefined($scope.parent.Coordinates) && !_.isNull($scope.parent.Coordinates) && $scope.parent.Coordinates.length > 0) {
                        // Add marker to map
                        _map.marker.add(ol.proj.fromLonLat([$scope.parent.Coordinates[0].Longitude, $scope.parent.Coordinates[0].Latitude]), _map.map.getView().getZoom());
                    }
                }
            }

            //_db.tasks.get(task.Id).then(function (dbTask) {

            //    for (var col in dbTask.Activity.Columns) {
            //        if (task.Activity.Columns[col].Value != dbTask.Activity.Columns[col].Value) {
            //            alert("task needs to be saved");
            //        }
            //    }
            //})

            // Scroll to task
            $scope.scrollTask(task, false);
        };

        // Open form
        $scope.openForm = function ($event, task) {

            // Stop event
            $event.preventDefault();
            $event.stopPropagation();

            // Open or close form
            $scope.page.open = !$scope.page.open;
            if ($rootScope.selectedTask != task && $scope.page.open == false) {
                if ($scope.sw) {
                    $scope.sw.stopStopWatch();
                }
                $scope.page.open = true;
            }

            // Select task
            $scope.selectTask($event, task, $scope.page.open);
        };

        // Open task
        $scope.openTask = function ($event, task) {

            // Clear selected task
            if ($scope.page.open) {
                $scope.page.open = false;
            }

            // Prevent
            $event.preventDefault();
            $event.stopPropagation();

            $scope.selectedTask = task;

            // New panel            
            $scope.add(new PagePanel({ id: task.TaskId, title: $filter('translate')('labels.subtasks'), subtitle: task.Description, frame: 'app/taken/taken.view.html', parent: task }));
        }

        // Enable draw mode
        $scope.draw = function ($event, task) {
            // Start draw
            $rootScope.$broadcast('application:draw:start', {});
        }

        // Filter
        $scope.filterByStatus = function () {
            return function (task) {
                // Filter options
                var filter = ['Planned', 'Started'];
                if ($rootScope.settings.showFinishedTasks)
                    filter.push('Finished');
                return (_.indexOf(filter, task.Status) > -1);
            };
        };

        // Watch parent. If set load subtasks
        $scope.$watch(function () { return $scope.parent; }, function (newValue, oldValue) {
            // Show loading
            $rootScope.$broadcast('loading:show');
            // Load subtasks from IndexedDB
            $scope.reload().finally(function () {
                // Hide loading
                $rootScope.$broadcast('loading:hide');
            });
        }, true);

        // Watch project selection
        $scope.$watch(function () { return $localStorage.defaultProject; }, function (newValue, oldValue) {
            $scope.mainTaskActivities = $scope.MainTaskActivities();
        });

        // Reload
        $rootScope.$on('application:tasks:reload', function () {
            // Show loading
            $rootScope.$broadcast('loading:show');
            // Load subtasks from IndexedDB
            $scope.reload().finally(function () {
                // Hide loading
                $rootScope.$broadcast('loading:hide');
            });
        });

        $scope.$watch('sw', function (value) {
            /*Checking if the given value is not undefined*/
            if (value) {
                $scope.Obj = value;
                /*Injecting the Method*/
                $scope.Obj.stopStopWatch = function () {
                    $scope.stop();
                }
            }
        });


        // Watch date type
        $scope.$watch(function () { return $scope.date.type; }, function (newValue, oldValue) {
            if (!_.isNull($scope.date.type.from))
                $scope.date.from = $scope.date.type.from;
            if (!_.isNull($scope.date.type.to))
                $scope.date.to = $scope.date.type.to;
        });

        // Find task
        $scope.taskSelectListener = $rootScope.$on('application:tasks:select', function ($event, feature) {

            // Only if feature is set and any tasks
            if (!$scope.last() || _.isNull(feature) || _.isUndefined(feature) || $scope.tasks.length == 0)
                return;

            // Geoline
            var geoline = feature.get('geoline');
            if (_.isUndefined(geoline))
                return;

            // Hit
            var hit = false;

            // Find task by CdoId and Coordinates
            _.each(_.filter($scope.tasks, { CdoId: geoline.CdoId }), function (task) {
                if ((task.Coordinates.length > 0 && _.startsWith(task.Coordinates[0].Latitude, geoline.Points[0].Latitude) && _.startsWith(task.Coordinates[0].Longitude, geoline.Points[0].Longitude))) {
                    // Hit
                    hit = true;
                    // Apply
                    $scope.$apply(function () {

                        // Open or close form
                        var form = $rootScope.selectedTask == task && $scope.page.open;

                        // Select task
                        $scope.selectTask($event, task, false);
                    });
                }
            });
        });

        // On destroy panel
        $scope.$on('$destroy', function () {
            // Destroy events
            $scope.taskSelectListener();
        });

    }]);
}());