(function () {

    // Strict
    'use strict';

    // App
    var app = angular.module('app', ['ngRoute', 'ngSanitize', 'ngResource', 'ngAnimate', 'ngStorage', 'ui.bootstrap',
        'angular-confirm', 'pascalprecht.translate', 'toastr', 'smart-table', 'indexedDB',
        'duScroll', 'tmh.dynamicLocale', 'angular.filter', 'ng.deviceDetector', 'treeControl']);

    // LoDash
    app.factory('_', function () {
        return window._; // assumes lodash has already been loaded on the page
    });

    // Router
    app.config(['$routeProvider', function ($routeProvider) {
        $routeProvider.when('/login', {
            templateUrl: 'app/login/login.view.html',
            controller: 'login.controller',
            authenticate: false
        }).when('/logout', {
            template: '',
            controller: ['$location', 'auth.factory', function ($location, _auth) {
                // Logout
                _auth.logout().then(function () {
                    // Redirect to login
                    $location.path('/login');
                });
            }],
            authenticate: false
        }).when('/home', {
            templateUrl: 'app/home/home.view.html',
            controller: 'home.controller',
            authenticate: true
        }).otherwise({
            redirectTo: '/logout' // Default
        });
    }]);

    // Http provider
    app.config(['$httpProvider', function ($httpProvider) {
        // OAuth http interceptor
        $httpProvider.interceptors.push('oauthInterceptor');
    }]);

    // i18n
    app.config(['$translateProvider', function ($translateProvider) {
        // Sanitize
        $translateProvider.useSanitizeValueStrategy(null);
        // Translation        
        $translateProvider.useStaticFilesLoader({
            prefix: './assets/i18n/',
            suffix: '/translation.json'
        });
        $translateProvider.preferredLanguage('en-us');
    }]);

    // Indexed DB
    app.config(['$indexedDBProvider', function ($indexedDBProvider) {

        // Create database
        $indexedDBProvider
        .connection('GeostructSurvey-2')
        .upgradeDatabase(20, function (event, db, tx) {
            var geometries = db.createObjectStore('Geometries', { keyPath: "GeometryId", autoIncrement: true });
        }).upgradeDatabase(21, function (event, db, tx) {
            var tasks = db.createObjectStore("Tasks", { keyPath: "Id", autoIncrement: true });
        }).upgradeDatabase(22, function (event, db, tx) {
            var logs = db.createObjectStore("Logs", { keyPath: "Id", autoIncrement: true });
        });
    }]);

    // Dynamic Locale
    app.config(function (tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern('./assets/vendor/angular-i18n-1.5.0/angular-locale_{{locale}}.min.js');
    });

    // DatePicker Decorator
    app.config(function ($provide) {
        $provide.decorator('uibDatepickerDirective', function ($delegate) {
            angular.forEach($delegate, function (directive) {
                var originalCompile = directive.compile;
                var originalLink = directive.link;
                if (originalCompile) {
                    directive.compile = function () {
                        return function (scope, element, attrs, ctrls) {
                            originalLink.apply(this, arguments);
                            var datepickerCtrl = ctrls[0];
                            var ngModelCtrl = ctrls[1];
                            if (ngModelCtrl) {
                                scope.$on('$localeChangeSuccess', function () {
                                    datepickerCtrl.refreshView();
                                });
                            }
                        };
                    };
                }
            });
            return $delegate;
        });
    });

    // Run
    app.run(['$rootScope', '$location', '$localStorage', 'auth.factory', '$filter', '$translate', '$window', 'tmhDynamicLocale', 'deviceDetector', function run($rootScope, $location, $localStorage, _auth, $filter, $translate, $window, tmhDynamicLocale, deviceDetector) {

        // Is token expired
        if (_auth.isTokenExpired())
            $location.path('/logout');

        // Detect iPad
        $rootScope.device = deviceDetector.device;
        $rootScope.os = deviceDetector.os;

        // Online / Offline
        $rootScope.online = navigator.onLine;
        $window.addEventListener("offline", function () {
            $rootScope.$apply(function () {
                $rootScope.online = false;
            });
        }, false);
        $window.addEventListener("online", function () {            
            $rootScope.$apply(function () {
                $rootScope.online = true;
            });
        }, false);

        // Write settings to rootScope
        var settings = _.clone(customerSettings);   
        if ($localStorage.settings) {
            settings.dateFormat = $localStorage.settings.dateFormat;
            settings.languageFormat = $localStorage.settings.languageFormat;
            settings.mapbaselayer = $localStorage.settings.mapbaselayer;
            settings.decimalSeperator = $localStorage.settings.decimalSeperator;
            settings.defaultReferenceId = $localStorage.settings.defaultReferenceId;
            settings.drawUseSnap = $localStorage.settings.drawUseSnap;
            settings.showFinishedTasks = $localStorage.settings.showFinishedTasks;
            settings.moveSelectedPosition = $localStorage.settings.moveSelectedPosition;
            settings.gpsTrackLayer = $localStorage.settings.gpsTrackLayer;
            settings.gpsTrackInterval = $localStorage.settings.gpsTrackInterval;
            settings.gpsTrackTolerance = $localStorage.settings.gpsTrackTolerance;
            settings.defaultProjectFromTask = $localStorage.settings.defaultProjectFromTask;

        }
        // Settings besta? Nee dan op 'true''zetten
        if (typeof settings.defaultProjectFromTask === "undefined") {
            settings.defaultProjectFromTask = true;
        }

        $localStorage.settings = settings;

        // MapQuest support has ended. Replaced with OpenstreetMap
        if ($localStorage.settings.mapbaselayer == 'mapquestosm')
            $localStorage.settings.mapbaselayer = 'olosm';

        $rootScope.settings = _.clone($localStorage.settings);
        $rootScope.applicationOnline = _.isUndefined($localStorage.applicationOnline) ? $rootScope.online : $localStorage.applicationOnline;

        // Regex
        $rootScope.regex = {
            int: /^[0-9]*$/,
            double: /^\-?\d+([,.]\d+)?$/
        };

        // Map always on top
        $rootScope.mapAlwaysOnTop = false;
        if (!_.isUndefined(customerSettings.mapAlwaysOnTop) && !_.isNull(customerSettings.mapAlwaysOnTop))
            $rootScope.mapAlwaysOnTop = customerSettings.mapAlwaysOnTop;

        // routeChange
        $rootScope.$on('$routeChangeStart', function (event, next) {
            if (next.authenticate && !_auth.isAuthenticated())
                $location.path('/login');
        });

        // Change language
        $rootScope.$watch(function () { return $localStorage.settings.languageFormat; }, function (newValue, oldValue) {
            // Use language
            $translate.use(newValue);
            tmhDynamicLocale.set(newValue);
            // Broadcast event
            $rootScope.$broadcast('application:language:change', $localStorage.settings.languageFormat);
        });

        // Watch online/offline
        $rootScope.$watch(function () { return $rootScope.online; }, function (newValue, oldValue) {            
            // Only if user set to online
            if (!_.isUndefined($localStorage.applicationOnline) && !$localStorage.applicationOnline)
                return;
            $rootScope.applicationOnline = $rootScope.online;
        });

    }]);

}());