(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('settings.controller', ['$rootScope', '$scope', '$localStorage', '$location', '$filter', '$uibModalInstance', '$q', 'spatialreference.factory', 'config', 'gps.service', 'toastr', 'api.factory', 'db.factory', function ($rootScope, $scope, $localStorage, $location, $filter, $uibModalInstance, $q, _sr, _config, _gps, toastr, _api, _db) {

        // Settings
        $scope.settings = angular.copy($rootScope.settings);

        // Config
        $scope.config = _config.version;

        // SpatialReferences
        $scope.spatialReferences = _sr.all();

        // Layers, only vector layers, not WMS layers
        $scope.layers = _.orderBy(_.reject(_.reject($localStorage.map.layers, { CdoId: 0 }), { IsEditable: false, Visible: false }), ['Name']);
        $scope.layers.unshift({ CdoId: null, Name: $filter('translate')('status.None') });

        // Connect GPS Bluetooth Rover
        $scope.connectGpsHub = function () {
            _gps.connect().then(function () { }, function () {
                toastr.error($filter('translate')('messages.gpsroverconnectfail'));
            });
        }

        // Tree Control
        $scope.treeControlOptions = {
            loading: false,
            dirSelectable: true,
            isSelectable: function (node) {
                return (node.Handle !== undefined);
            },
            injectClasses: {
                iLeaf: 'fa',
                iExpanded: "fa fa-minus",
                iCollapsed: "fa fa-plus"
            }
        }

        // Projects
        $scope.projects = [];
        if ($rootScope.applicationOnline) {
            // Loading
            $scope.treeControlOptions.loading = true;
            _api.getProjects(null).then(function (projects) {
                _.each(projects, function (project) {
                    project.children = [{ Name: $filter('translate')('labels.loadsubprojects') + ' ... ' }];
                });
                $scope.projects = projects;
            }).finally(function () {
                // Loading
                $scope.treeControlOptions.loading = false;
            });
        }

        // Load subProject
        $scope.fetchProjects = function (node, expanded) {
            _api.getProjects({ Handle: node.Handle }).then(function (subprojects) {
                if (subprojects.length > 0) {
                    _.each(subprojects, function (subproject) {
                        subproject.children = [{ Name: $filter('translate')('labels.loadsubprojects') + ' ... ' }];
                    });
                    node.children = subprojects;
                } else {
                    node.children[0].Name = $filter('translate')('labels.nosubprojects') + ' ... ';
                }
            });
        }

        // Unset project
        $scope.unsetProject = function () {
            $scope.selectedTreeNode = undefined;
            $scope.defaultProject = undefined;
        }

        // Select project
        $scope.selectProject = function (node, selected) {
            $scope.defaultProject = node;
        }

        // default project
        $scope.defaultProject = angular.copy($localStorage.defaultProject);

        // Disconnect GPS Bluetooth Rover
        $scope.disconnectGpsHub = function () {
            _gps.disconnect();
        }

        $scope.created = false;
        $scope.createCache = function () {
            var promises = [];
            promises.push(new Promise(function (resolve) {
                _db.geometries.all().then(function (geoms) {
                    resolve(geoms)
                });
            }));

            promises.push(new Promise(function (resolve) {
                _db.tasks.all().then(function (tasks) {
                    resolve(tasks)
                });
            }));

            promises.push(new Promise(function (resolve) {
                _db.logs.all().then(function (logs) {
                    resolve(logs)
                });
            }));

            promises.push(new Promise(function (resolve) {
                resolve($localStorage)
            }));

            Promise.all(promises).then(function (values) {

                var data = {
                    geometries: values[0],
                    tasks: values[1],
                    logs: values[2],
                    localStorage: values[3]
                }
                var json = JSON.stringify(data);
                var blob = new Blob([json], { type: "application/json" });
                var url = URL.createObjectURL(blob);
                var element = document.createElement('a');
                element.setAttribute('href', url);
                element.setAttribute('download', "cache.json");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            });
        };

        // Save
        $scope.save = function () {
            // Save settings into localStorage and rootScope
            $localStorage.settings = $scope.settings;
            $rootScope.settings = $scope.settings;

            // Set CdoType to visible
            var cdoType = _.find($localStorage.cdotypes, { 'CdoId': $scope.settings.gpsTrackLayer });
            if (!_.isUndefined(cdoType) && !_.isNull(cdoType)) {
                cdoType.Visible = true;
            }

            // Project
            $localStorage.defaultProject = $scope.defaultProject;

            // Redirect to default path
            $uibModalInstance.close();
        };

        // Cancel
        $scope.cancel = function () {

            // Restore settings
            $scope.settings = angular.copy($localStorage.settings);
            $rootScope.settings = $localStorage.settings;

            // Project
            $scope.defaultProject = angular.copy($localStorage.defaultProject);
            
            // Redirect to default path
            $uibModalInstance.dismiss('cancel');
        };
    }]);

}());